/**
 * Created by ts on 2016/12/20.
 * 备注：
 *  命名规则：函数的名称应该使用动词+名词，变量名则最好使用名词。
 *      常量区变量请全部用大写字母,且单词间用下划线链接；
 *      方法名、普通变量名请使用小驼峰命名规则，即除了第一个单词的首字母外其余单词首字母大写。
 */
(function (global, $, undefined) {
    global.tools = global.tools || {};


    /***********************************************************
     *********************** 方法区 *****************************
     **********************************************************/
    // 获取url参数
    global.tools.getQueryString = function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    };
    global.tools.getUrlParams = function (name) {
        return  global.tools.getQueryString(name);
    };
    /**
     * 密码加密
     * @param option
     */
    global.tools.AESEncrypt = function(word){
        var key = CryptoJS.enc.Utf8.parse("AQ4S10D7d9K8c64D");
        var iv = CryptoJS.enc.Utf8.parse('dwvNVXzyXiq37u-A');
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7});
        return encrypted.toString();
    };
    /**
     * 储存cookie
     * @param option
     */
    global.tools.setCookie = function(cookieData){
        var date = new Date();
        if (cookieData.hour) {
            date.setTime(date.getTime() + cookieData.hour * 60 * 60 * 1000);
            var expires = ";expires=" + date.toGMTString();
        } else {
            date.setTime(date.getTime() + cookieData.day * 24 * 60 * 60 * 1000);
            var expires = ";expires=" + date.toGMTString();
        }
        document.cookie = cookieData.name + "=" + cookieData.value + expires + "; path=/";
    };
    /**
     * 读取cookie
     * @param option
     */
    global.tools.getCookie = function(name){
        var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        if (arr = document.cookie.match(reg)) {
            return unescape(arr[2]);
        } else {
            return null;
        }
    };
    /**
     * 删除cookie
     * @param option
     */
    global.tools.delCookie = function(name){
        this.setCookie({
            name: name,
            value: '',
            day: -20
        })
    };/**
     * 储存cookie
     * @param option
     */
    global.tools.setCookie = function(cookieData){
        var date = new Date();
        if (cookieData.hour) {
            date.setTime(date.getTime() + cookieData.hour * 60 * 60 * 1000);
            var expires = ";expires=" + date.toGMTString();
        } else {
            date.setTime(date.getTime() + cookieData.day * 24 * 60 * 60 * 1000);
            var expires = ";expires=" + date.toGMTString();
        }
        document.cookie = cookieData.name + "=" + cookieData.value + expires + "; path=/";
    };
    /**
     * 读取cookie
     * @param option
     */
    global.tools.getCookie = function(name){
        var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        if (arr = document.cookie.match(reg)) {
            return unescape(arr[2]);
        } else {
            return null;
        }
    };
    /**
     * 删除cookie
     * @param option
     */
    global.tools.delCookie = function(name){
        this.setCookie({
            name: name,
            value: '',
            day: -20
        })
    };
    /**
     * 获取配置信息
     */
    global.tools.config = {}
    global.tools.getConfig = function (call) {
        var sendParams = {
            url: '/config/config',
            data: {}
        };
        tools.request(sendParams, function (data) {
            call&&call(data)
            tools.config = data
        });
    };
    /**
     * praise=点赞，want=想去，gone=住过/去过统一交互接口
     * @DateTime  2017-3-13T14:58:41+0800
     * @param  {object}  options      所有参数
     * @params {string}  selector     操作选择器
     * @params {string}  type         praise=点赞，want=想去，gone=住过/去过
     * @params {string}  resourceType 直接通过路径指定 {resourceType} picture=图片，
     * @params {string}  resourceType video=视频，hotel=宾馆酒店，goods=旅游商品，news=新闻资讯，scenery=景区
     * @params {number}  total        操作总数
     * @params {number}  resourceId   id
     * @params {number}  lang         语言参数
     */
    var currentId;
    var currentArray = [];
    tools.getInteraction= function(options){
        var $selector = options.selector;
        var error = "失败";
        var hasDo = "已操作";
        if(currentId != options.reId && $.inArray(options.reId,currentArray) == -1){
            currentId = options.resourceId;
            currentArray.push(options.resourceId);
            var params = {
                url : "thumb/saveThumb",
                data:options
            };
            if(options.token){
                tools.request(params,function(data){
                    if(options.total == ""){
                        options.total = 0;
                    }
                    if(data.code == 0){//第一次操作
                        $selector.find("p").html("点赞("+(Number(options.total) +1)+")");
                        $selector.append('<em class="click_tips" style="position: absolute">+1</em>');
                        $selector.find('em').animate({top: '-28px', 'opacity': '0'}, 950, function () {
                            $(this).remove();
                        });

                    }else if(data.code == 1){//操作失败
                        $selector.append('<em class="click_tips" style="position: absolute">'+data.message+'</em>');
                        $selector.find('em').animate({top: '-28px', 'opacity': '0'}, 950, function () {
                            $(this).remove();
                        });
                    }else{//已操作
                        $selector.append('<em class="click_tips" style="position: absolute">'+hasDo+'</em>');
                        $selector.find('em').animate({top: '-28px', 'opacity': '0'}, 950, function () {
                            $(this).remove();
                        });
                    }


                });
            }else {
                window.location.href = "login.html";
            }


        }else{
            $selector.append('<em class="click_tips" style="position: absolute">'+hasDo+'</em>');
            $selector.find('em').animate({top: '-28px', 'opacity': '0'}, 950, function () {
                $(this).remove();
            });
            return;
        }

    };
    //t推荐
    tools.getRecommend= function(options){
        var $selector = options.selector;
        var error = "失败";
        var hasDo = "已操作";
        if(currentId != options.reId && $.inArray(options.reId,currentArray) == -1){
            currentId = options.resourceId;
            currentArray.push(options.resourceId);
            var params = {
                url : "recommended/saveRecommend",
                data:options
            };
            if(options.token){
                tools.request(params,function(data){

                    if(options.total == ""){
                        options.total = 0;
                    }
                    if(data.code == 0){//第一次操作
                        $selector.find("i").html((Number(options.total) +1));
                        $selector.append('<em class="click_tips" style="position: absolute">+1</em>');
                        $selector.find('em').animate({top: '-28px', 'opacity': '0'}, 950, function () {
                            $(this).remove();
                        });

                    }else if(data.code == 1){//操作失败
                        $selector.append('<em class="click_tips" style="position: absolute">'+data.message+'</em>');
                        $selector.find('em').animate({top: '-28px', 'opacity': '0'}, 950, function () {
                            $(this).remove();
                        });
                    }else{//已操作
                        $selector.append('<em class="click_tips">'+hasDo+'</em>');
                        $selector.find('em').animate({top: '-28px', 'opacity': '0'}, 950, function () {
                            $(this).remove();
                        });
                    }


                });
            }else {
                window.location.href = "login.html";
            }


        }else{
            $selector.append('<em class="click_tips">'+hasDo+'</em>');
            $selector.find('em').animate({top: '-28px', 'opacity': '0'}, 950, function () {
                $(this).remove();
            });
            return;
        }

    };
    /**
     * 统一跳出
     * @param option
     */
    global.tools.jumpLogin = function(){
        window.location.href = './login.html?redirect=' + encodeURIComponent(window.location.href);
    };
    global.tools.stringifyArray = function(array){
        return JSON.stringify(array);
    };
    /**
     * 收藏
     * @param option
     */
    global.tools.setCollect = function(options){
        var token = this.getCookie('token'),
            _selector = options.selector,
            _val = _selector.attr("data-value");
        var $num = options.num;
        // console.log(parseInt($num.text()))
        if (token) {
            if (_val == 0) {
                _selector.css("position", "relative");
                this.request({
                    url: 'enshrine/saveEnshrine',
                    data: {
                        reId: options.id,
                        sourceType: options.type,
                        content: this.stringifyArray(options.content),
                        target: this.stringifyArray(options.title),
                        token:token
                    }
                },function (result) {
                    if (result.code == 0) {
                        if ($num) {
                            $num.text(parseInt($num.text()));
                        }
                        _selector.append('<em class="click_tips" style="position: absolute">+1</em>');
                        if(options.yl){
                            _selector.css("color","#ff993e");
                        }
                        if(options.active){
                            _selector.addClass("active");
                        }
                        _selector.find('em').animate({
                            top: '-28px',
                            'opacity': '0'
                        }, 950, function() {
                            $(this).remove();
                        });
                        _selector.attr("data-value", "1");
                        _selector.find("p").html('收藏('+(parseInt($num.text())+1)+')')
                    } else {
                        _selector.append('<em class="click_tips" style="position: absolute">已收藏</em>');

                        _selector.find('em').animate({
                            top: '-28px',
                            'opacity': '0'
                        }, 950, function() {
                            $(this).remove();
                        });
                    }
                })
            } else {
                _selector.append('<em class="click_tips" style="position: absolute">已收藏</em>');
                _selector.find('em').animate({
                    top: '-28px',
                    'opacity': '0'
                }, 950, function() {
                    $(this).remove();
                });
            }
        } else {
            window.location = 'login.html';
        }

    }
    /**
     * 输入框数字输入控制
     * @param params
     * {
     * el: object   css选择器
     * max: number  最大值
     * min: number  最小值
     * default: number 默认值
     * change: function 数值改变的回调 返回改变后的值
     * }
     * @returns {{init: init, event: event, update: update}}
     */
    global.tools.inputCount = function(param) {
        function checkNumber(str) {
            var reg = /^\d+$/;
            if (reg.test(str)) {
                return true;
            }
            return false;
        }
        function objFn (params) {
            this.obj = {
                init: function () {
                    this.oldval = '';
                    this.config = {
                        min: 0,
                        max: 999999
                    };
                    if (params && $.isPlainObject(params)) {
                        $.extend(this.config, params);
                    }
                    this.ipt = $(this.config.el).find('input');
                    var btns = $(this.config.el).find('i');
                    this.btn1 = btns.eq(0);
                    this.btn2 = btns.eq(1);
                    this.event();
                    return this;
                },
                event: function () {
                    var _this = this;
                    $(this.config.el).prop('tabIndex',-1).css('outline',"none");
                    this.oldval = this.config.default ? this.config.default : this.config.min;
                    this.ipt.val(this.oldval);
                    if(this.oldval > this.config.min){
                        this.btn1.removeClass('dio-disable');
                    } else {
                        this.btn1.addClass('dio-disable');
                    }
                    if(this.config.min === this.config.max){
                        this.btn1.addClass('dio-disable');
                        this.btn2.addClass('dio-disable');
                    } else{
                        this.btn2.removeClass('dio-disable');
                    }
                    this.btn1[0].onclick = function(){
                        var $el = $(this);
                        if($el.is('.dio-disable')){
                            return false;
                        }
                        var val = _this.ipt.val();
                        val--;
                        _this.oldval = val;
                        _this.ipt.val(val);
                        _this.changeBtnState();
                    };
                    this.btn2[0].onclick = function(){
                        var $el = $(this);
                        if($el.is('.dio-disable')){
                            _this.ipt.removeClass('inp-opreate-hover');
                            return false;
                        }
                        var val = _this.ipt.val();
                        val++;
                        _this.oldval = val;
                        _this.ipt.val(val);
                        _this.changeBtnState();
                    };
                    this.ipt[0].onkeyup = iptInput ;
                    function iptInput(){
                        var val = $(this).val();
                        if(!checkNumber(val)){
                            $(this).val(_this.oldval);
                            return false;
                        }
                        if(val-0 < _this.config.min ) {
                            $.daqMessage({
                                text: '最小购买数量为' + _this.config.min,
                                skin: 1,
                                position: 'center',
                                time: 1000
                            });
                            $(this).val(_this.oldval);
                        } else if( val-0 > _this.config.max) {
                            $.daqMessage({
                                text: '最大购买数量为' + _this.config.max ,
                                skin: 1,
                                position: 'center',
                                time: 1000
                            });
                            $(this).val(_this.oldval);
                        } else {
                            _this.oldval = val;
                            _this.changeBtnState();
                        }
                    }
                    $(this.config.el)[0].onblur = function (){
                        iptBlur();
                    };
                    _this.ipt[0].onfocus = iptFocus;
                    _this.ipt[0].onblur = iptBlur;
                    function iptFocus(){
                        _this.changeBtnState();
                    }
                    function iptBlur() {
                        _this.ipt.removeClass('inp-opreate-hover');
                        _this.btn1.removeClass('inp-opreate-curr');
                        _this.btn2.removeClass('inp-opreate-curr');
                    }
                },
                changeBtnState: function(){
                    var val = this.ipt.val()-0;
                    if( this.config.max === this.config.min) {
                        this.ipt.removeClass('inp-opreate-hover');
                        this.btn1.addClass('dio-disable').removeClass('inp-opreate-curr');
                        this.btn2.addClass('dio-disable').removeClass('inp-opreate-curr');
                    } else {
                        if(val < this.config.max && val > this.config.min) {
                            this.ipt.addClass('inp-opreate-hover');
                            this.btn2.removeClass('dio-disable').addClass('inp-opreate-curr');
                            this.btn1.removeClass('dio-disable').addClass('inp-opreate-curr');
                        } else if(val >= this.config.max ) {
                            this.ipt.removeClass('inp-opreate-hover');
                            this.btn2.addClass('dio-disable').removeClass('inp-opreate-curr');
                            this.btn1.removeClass('dio-disable').removeClass('inp-opreate-curr');
                        } else if(val <= this.config.min) {
                            this.ipt.removeClass('inp-opreate-hover');
                            this.btn1.addClass('dio-disable').removeClass('inp-opreate-curr');
                            this.btn2.removeClass('dio-disable').removeClass('inp-opreate-curr');
                        }
                    }
                    this.config.change && this.config.change(val);
                },
                /**
                 * @param param
                 * {
             *  max  number  最大值
             *  min  number  最小值
             *  val number   input框显示的值
             * }
                 */
                update:function  (param) {
                    this.config.max = param.max;
                    this.config.min = param.min;
                    if(this.oldval <= param.min) {
                        this.oldval = param.min;
                        this.ipt.val(param.min);
                    } else if(this.oldval >= param.max) {
                        this.oldval = param.max;
                        this.ipt.val(param.max);
                    } else if(this.oldval > param.min && this.oldval < param.max){
                        if(param.val >= 0) {
                            this.oldVal = param.val;
                            this.ipt.val(param.val);
                        }
                    }
                    this.changeBtnState();
                }
            };
            this.obj.init();
            return this.obj;
        }
        //return obj.init();
        return new objFn(param);
    };
    /**
     * 是否登录
     * @param callback 如果登录了执行的回调
     */
    global.tools.isLogin = function(callback) {
        if (!global.tools.getCookie('token')) {
            global.tools.doLogin();
            return false;
        }
        callback && callback();
    };
    // 统一登录处理
    global.tools.doLogin = function() {
        window.location.replace('./login.html?redirect=' + encodeURIComponent(window.location.href));
    };
    global.tools.goHome = function() {
        window.location.href = './index.html';
    };
    /**
     * 四则运算  计算小数的加减乘除出现误差的
     * @param num1
     * @param num2
     * @returns {{}}
     */
    global.tools.math = function (arg1, arg2) {
        return {
            // 两数字之和
            add: (function(arg1, arg2){
               var r1, r2, m, c;
               try {
                   r1 = arg1.toString().split(".")[1].length;
               }
               catch (e) {
                   r1 = 0;
               }
               try {
                   r2 = arg2.toString().split(".")[1].length;
               }
               catch (e) {
                   r2 = 0;
               }
               c = Math.abs(r1 - r2);
               m = Math.pow(10, Math.max(r1, r2));
               if (c > 0) {
                   var cm = Math.pow(10, c);
                   if (r1 > r2) {
                       arg1 = Number(arg1.toString().replace(".", ""));
                       arg2 = Number(arg2.toString().replace(".", "")) * cm;
                   } else {
                       arg1 = Number(arg1.toString().replace(".", "")) * cm;
                       arg2 = Number(arg2.toString().replace(".", ""));
                   }
               } else {
                   arg1 = Number(arg1.toString().replace(".", ""));
                   arg2 = Number(arg2.toString().replace(".", ""));
               }
               return (arg1 + arg2) / m;
           })(arg1, arg2),
            // 两数只差
            sub: (function(arg1, arg2){
                var r1, r2, m, n;
                try {
                    r1 = arg1.toString().split(".")[1].length;
                }
                catch (e) {
                    r1 = 0;
                }
                try {
                    r2 = arg2.toString().split(".")[1].length;
                }
                catch (e) {
                    r2 = 0;
                }
                m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
                n = (r1 >= r2) ? r1 : r2;
                return Number(((arg1 * m - arg2 * m) / m).toFixed(n));
            })(arg1, arg2),
            // 两数相乘
            mul: (function(arg1, arg2){
                var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
                try {
                    m += s1.split(".")[1].length;
                }
                catch (e) {
                }
                try {
                    m += s2.split(".")[1].length;
                }
                catch (e) {
                }
                return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
            })(arg1, arg2),
            // 两数相除
            div: (function(arg1, arg2){
                var t1 = 0, t2 = 0, r1, r2;
                try {
                    t1 = arg1.toString().split(".")[1].length;
                } catch (e) {}
                try {
                    t2 = arg2.toString().split(".")[1].length;
                } catch (e) {
                } with (Math) {
                    r1 = Number(arg1.toString().replace(".", ""));
                    r2 = Number(arg2.toString().replace(".", ""));
                    return (r1 / r2) * pow(10, t2 - t1);
                }
            })(arg1, arg2)
        }
    };

    /**
     * [regMatch 验证字符串格式]
     * @param  {string} str  字符串
     * @param  {string} name 格式
     * @return {boolean}      是否符合对应的格式 符合返回true  不符合返回false
     */
    global.tools.regMatch = function (str, name) {
        var regexp = null;
        switch (name) {
            case 'name':
                regexp = /.{2,16}/;
                break;
            case 'pinyin':
                regexp = /^[a-zA-Z]{4,50}$/;
                break;
            case 'phone':
                regexp = /^1\d{10}$/;
                break;
            case 'needPassengerInfoOther1':
                regexp = /.+/;
                break;
            case 'needPassengerInfoOther2':
                regexp = /.+/;
                break;
            case 'idcard':
                regexp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                break;
            case 'HKAndMacauPermit':
                regexp = /.+/;
                break;
            case 'Passport':
                regexp = /(P\d{7})|(G\d{8})/;
                break;
            case 'TaiwanPermit':
                regexp = /^[a-zA-Z0-9]{5,21}$/;
                break;
            case 'Idcard':
                regexp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                break;
        }
        return regexp.test(str);
    };
    // tools.getConfig();
    /**
     * 设置2级页面banner
     */
    global.tools.setTowLevelBanner = function () {
        var $elment = $('.js_towlevelbanner')
        var channelCode = tools.getUrlParams('channelCode')
        var params = {
            url: 'siteChannel/channelList',
            data:{
                channelCode: channelCode
            }
        };
        tools.request(params, function(res){
            var _html = []
            var data = {},
                len = 0
                if(res.datas) {
                    data = res.datas;
                    len = res.datas.length;
                }
            if(res.code === 0) {
                if(res.datas.length>0){
                    // banner
                    if(res.datas[0].navImage !== ''){
                        $elment.html('<img src="'+res.datas[0].navImage+'" alt="">')
                    }else{
                        $elment.html('<img src="image/nobanner.jpg" alt="">')
                    }
                    // 面包削
                    //当前位置
                    _html.push('<i class="daq-icon ic-home">&#xe729;</i>您当前的位置：');
                    // console.log(data)
                    if (data[0].name !== 'wzsy') {
                        _html.push('<a href="./index.html">网站首页</a>');
                        _html.push('<i class="crumb-sign ' + ('-'== '&#xe618;' ? 'sysfont' : '') + '">' + '-' + '</i>');
                    };
                    for (var i = 0; i < len; i++) {
                        if (i === len - 1) {
                            if(data[i].code != 'wzlm') {
                                _html.push('<a href="' + (data[i].url.length ? decodeURIComponent(data[i].url) : "javascript:;") + '" class="on">' + ' ' + data[i].name + '</a>');
                            }
                        } else {
                            if(data[i].code != 'wzlm') {
                            _html.push('<a href="' + (data[i].url.length ? decodeURIComponent(data[i].url) : "javascript:;") + '">' + ' ' + data[i].name + '</a>');
                            _html.push('<i class="crumb-sign">' + '-' + '</i>');
                            }
                        };
                    };
                    $('.js_curmbs').html(_html)
                }else{
                    $elment.html('<img src="image/nobanner.jpg" alt="">')
                }
            } else{
                $elment.html('<img src="image/nobanner.jpg" alt="">')
            }
        })
    }
    global.tools.validate = function (value, type, minlength, maxlength) {
        var value = $.trim(value);
        // 非空验证
        if ('require' === type) {
            return !!value;
        }
        // 手机号验证
        if ('phone' === type) {
            return /^1\d{10}$/.test(value);
        }
        // 邮箱格式验证
        if ('email' === type) {
            return /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/.test(value);
        }
        //验证长度
        if ('length' === type) {
            if (value.length <= minlength || value.length >= maxlength) {
                return false;
            } else {
                return true;
            }
        }
        //汉字和字母验证规则
        if ('chinese' === type) {
            return /^([\u4E00-\uFA29]*[a-z]*[A-Z]*[0-9]*)+$/.test(value);
        }
    };
    /**
     * 高亮替换
     * @param str  字符串
     * @param key  关键字
     */
    global.tools.highLight = function(str, key){
        var reg = new RegExp("("+ key +")","ig");
        str = str.replace(reg,"<b class='high-light'>$1</b>");
        return str;
    };
})(window, jQuery);

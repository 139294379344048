/*
 * @Author: UEDHE
 * @Date:   2017-09-18 11:48:44
 * @Last Modified by:   'hejianping'
 * @Last Modified time: 2017-12-13 17:51:52
 */
;
(function($) {
    /**
     * [LightBox description]
     * @param {[String]} element [负节点]
     * @param {[Object]} config  [description]
     * @param {[String]} child [字节点]
     * @param {[String]} prevText [上一页的text]
     * @param {[String]} nextText [下一页的text]
     */
    function LightBox(element, config) {
        this.ele = element;
        this.config = {
            child: '.waterfall-item',
            prevText: '上一页',
            nextText: '下一页',
            callback:function(){

            }
        };
        // 默认参数扩展
        if (config && $.isPlainObject(config)) {
            $.extend(this.config, config);
        };
    };

    LightBox.prototype = {
        init: function() {
            this.eventBind();
        },
        /**
         * [eventBind 事件]
         */
        eventBind: function() {
            var _this = this,
                config = _this.config;
            _this.ele.on('click', config.child, function() {
                var $this = $(this);
                _this.index = $this.index();
                _this.len = _this.ele.find(config.child).length;
                _this.render();
                _this.getparams(_this.index);
            });


        },
        /**
         * [render 模版]
         */
        render: function() {
        	var _this = this;
            var _html = '<div class="dialog">' +
                '<div class="dialog-hd">' +
                '<a href="javascript:;" class="daq-icon">&#xe656;</a>' +
                '</div>' +
                '<div class="dialog-bd"><img src="" /><p class="title mask"></p></div>' +
                '<div class="dialog-ft">' +
                '<a href="javascript:;" class="prev daq-icon opacity">&#xe6b2;</a>' +
                '<a href="javascript:;" class="next daq-icon opacity">&#xe6b1;</a>' +
                '</div>' +
                '<div class="mask"></div>' +
                '</div>';
            $('body').append(_html);
            //上一页
            $('.dialog .prev').on('click', function() {
                _this.prev();
            });
            //下一页
            $('.dialog .next').on('click', function() {
                _this.next();
            });
            //remove
            $('.dialog .mask,.dialog .dialog-hd').on('click', function() {
                _this.hide();
            });
        },
        /**
         * [getparams 获取相应参数]
         * @param  {[Number]} num [当前列]
         */
        getparams: function(num) {
            var _this = this,
                config = _this.config,
                $item = _this.ele.find(config.child).eq(num),
                src = $item.find('img').attr('src'),
                content = $item.find('.title').text(),
                $img = $('.dialog').find('img'),
                $title = $('.dialog').find('.title');
            $img.attr('src', src);
            $title.html(content);
            _this.computeImgPosition($img);
            typeof config.callback === "function" && config.callback($item);
        },
        /**
         * [getparams 计算宽高]
         * @param  {[Object]} num [图片对象]
         */
        computeImgPosition: function(img) {
            $('.dialog .dialog-bd').css('width','auto');
            var winH = $(window).height(),
                winW = $(window).width(),
                imgW = img.width(),
                imgH = img.height(),
                imgScale = imgW / imgH;
            if (imgH >= winH) {
                imgH = winH - 300;
                imgW = parseInt(imgH * imgScale);
            }
            if (imgW >= winW) {
                imgW = winW - 300;
                imgH = parseInt(imgW / imgScale);
            }
            $('.dialog .dialog-bd').css({
                    width: imgW,
                    height: imgH,
                    marginLeft: -(imgW + 20) / 2,
                    marginTop: -(imgH + 20) / 2
                },
                200);
        },
        /**
         * [hide 移除dialog]
         */
        hide: function() {
            $('.dialog').hide(500, function() {
                $(this).remove();
            })
        },
        /**
         * [next 下一页]
         */
        next: function() {
            var _this = this;
            if (_this.index >= _this.len-1) {
                return false;
            };
            _this.index++;
            _this.getparams(_this.index);
        },
        /**
         * [next 上一页]
         */
        prev: function() {
            var _this = this;
            if (_this.index <= 0) {
                return false;
            };
            _this.index--;
            _this.getparams(_this.index);
        }
    }

    // 添加到window对象上
    window.LightBox = LightBox;
    // 封装到jquery对象上去
    $.fn.daqLightBox = function(config) {
        var daqLightBox = new LightBox(this, config);
        daqLightBox.init();
    };
})(window.jQuery || $);

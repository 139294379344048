/**
 * Created by chengwb on 2016/8/6.
 * 备注：
 *  命名规则：函数的名称应该使用动词+名词，变量名则最好使用名词。
 *      常量区变量请全部用大写字母,且单词间用下划线链接；
 *      方法名、普通变量名请使用小驼峰命名规则，即除了第一个单词的首字母外其余单词首字母大写。
 */
(function (global, $, undefined) {
    global.tools = global.tools || {};

    /***********************************************************
     *********************** 常量区 *****************************
     **********************************************************/
    //暂无数据
    global.tools.NO_DATA = '<p class="no-data">暂无数据！</p>';
    global.tools.NO_DATA1 = '<p class="no-data1">暂无数据！</p>';
    //获取数据失败，刷新页面
    global.tools.TRY_REFRESH = '<p class="try-refresh">获取服务器数据失败，请尝试刷新浏览器页面！</p>';
    global.tools.TRY_REFRESH1 = '<p class="try-refresh1">获取服务器数据失败，请尝试刷新浏览器页面！</p>';

    /***********************************************************
     *********************** 方法区 *****************************
     **********************************************************/

    var _prefix = '/server'; //可配置统一前缀，配合node端动态路由，请勿随意改动
    /**
     * 设置ajax请求地址前缀，例子：如果node后台识别的是/api开头的地址，则可以设置_prefix为‘/api’
     * 设置后在下面的request函数中生效，所以有需求的话，请先设置前缀
     * @param prefix 前缀字符串
     */
    global.tools.setAjaxPrefix = function (prefix) {
        _prefix = prefix;
    };

    /**
     * ajax请求，统一处理
     * @param options 必传
     * {
       *  url: 接口地址，以‘/’开头，（纯url地址，参数数据可以放data里面）
       *  method: GET PUT POST DELETE，默认GET
       *  data: 数据,
       *  type: true/false  (使用前缀时)是否使用电商接口  true 为使用电商接口  默认为false 使用资讯接口
       *  prefix: true/false true使用前缀（需通过setAjaxPrefix方法设置），false不使用前缀，默认值为true
       * }
     * @param callback 回调方法，必传
     */
    global.tools.request = function(options, callback) {
        // var prefix = _prefix ? (options.prefix !== false ? _prefix : '') : '';
        var prefix = options.prefix !== false ? ( options.type === true ? "/server/b/" : "/server/a/"): '';
        $.ajax({
            url: prefix + options.url+"?"+ new Date().toTimeString() ,
            type: options.method || 'GET',//GET PUT POST DELETE
            data: options.data || {},
            traditional: true,//{foo:["bar1", "bar2"]} 转换为 '&foo=bar1&foo=bar2'
            complete: function (jqXHR, textStatus) {
                if (textStatus === 'success') {
                    var result = $.parseJSON(jqXHR.responseText);
                    callback(result);
                } else if(textStatus === 'timeout') {
                    callback({
                        error: true,
                        message: '服务器响应超时'
                    });
                } else {
                    callback({
                        error: true,
                        message: '服务器错误'
                    });
                }
            }
        });
    };
    global.tools.requestRetailers = function(options, callback) {
        // var prefix = _prefix ? (options.prefix !== false ? _prefix : '') : '';
        var prefix = options.prefix !== false ? ( options.type === true ? "/server/b/" : "/server/a/"): '';
        $.ajax({
            url: prefix + options.url,
            type: options.method || 'GET',//GET PUT POST DELETE
            data: options.data || {},
            traditional: true,//{foo:["bar1", "bar2"]} 转换为 '&foo=bar1&foo=bar2'
            complete: function (jqXHR, textStatus) {
                if (textStatus === 'success') {
                    var result = $.parseJSON(jqXHR.responseText);
                    callback(result);
                } else if(textStatus === 'timeout') {
                    callback({
                        error: true,
                        message: '服务器响应超时'
                    });
                } else {
                    callback({
                        error: true,
                        message: '服务器错误'
                    });
                }
            }
        });
    };
    /**
     * 正在加载中提示
     * @param option
     *  可以是对象
     *  {
     *      selector: '',//选择器
     *      position: ''//插入的位置（相对于选择器而言）before/in/after,前、中、后
    *  }
     *  也可以是字符串(表示selector，插入位置默认为in)
     * @param custom 回调函数，可以加工loadTip也可以自定义提示
     * @returns {{clean: clean}} 如果自定义loadTip则clean可能无效，需要自己根据自定义的tip进行清空处理（先使用着，待完善处理）
     */
    global.tools.loading = function (option, custom) {
        var loadTip = '<div class="loading-tips" style=""><img src="image/loading.gif"><p>数据加载中...</p></div>';
        if (custom && $.isFunction(custom)) {
            loadTip = custom(loadTip);
        }

        if(typeof option === 'string') {
            $(option).append(loadTip);
        } else {
            switch (option.position) {
                case 'in':
                    $(option.selector).append(loadTip);
                    break;
                case 'after':
                    $(option.selector).after(loadTip);
                    break;
                case 'before':
                    $(option.selector).before(loadTip);
                    break;
                default:
                    $(option.selector).append(loadTip);
                    break;
            }
        }

        function clean() {
            if(typeof option === 'string') {
                $(option + ' .loading-tips').remove();
            } else {
                switch (option.position) {
                    case 'in':
                        $(option.selector + ' .loading-tips').remove();
                        break;
                    case 'after':
                    case 'before':
                        $(option.selector).siblings('.loading-tips').remove();
                        break;
                    default:
                        $(option.selector + ' .loading-tips').remove();
                        break;
                }
            }
        }

        return {
            clean: clean
        };
    };

    /**
     * 内容长度限制，转换为省略号结尾
     * @param content 目标内容
     * @param length 限制的长度
     * @returns {*} 超过限制长度的数据则返回限制长度的字符串加上...，没超过则原文返回
     */
    global.tools.ellipsisContent = function (content, length) {
        var result;

        if (!content || typeof content !== 'string' ||
            typeof length !== 'number' || content.length <= length || length <= 0) {
            result = content;
        } else {
            result = content.substr(0, length) + "...";
        }
        return result;
    };

    /**
     * 图片加载异常时调用，一般用于img中的onerror=tools.errImg(this)
     * @param tag
     */
    global.tools.errImg = function (el) {
        el.src = "../image/list-xxentertainment-banner.jpg";
        el.onerror = null;
    };

    /**
     * 瀑布流图片加载异常时调用，一般用于img中的onerror
     * @param el 当前图片元素
     * @param defaultHeight 设置该元素的高度，默认为bad.jpg的高度
     */
    global.tools.waterfallErrImg = function (el, defaultHeight) {
        defaultHeight = defaultHeight || 240;//240px为bad.jpg的高度
        $(el).height(defaultHeight);

        el.src = "/image/bad.jpg";
        el.onerror = null;
    };

    /**
     *百度分享
     */
    global.tools.share = function () {
        window._bd_share_config = {
            "common": {
                "bdSnsKey": {},
                "bdText": "",
                "bdMini": "2",
                "bdDesc": "",
                "bdMiniList": false,
                "bdPic": "",
                "bdUrl": "",
                "bdStyle": "2",
                "bdSize": "16"/*,
                "bdPopupOffsetLeft": "30"*/
            },
            "share": {}
        };
        with (document)0[(getElementsByTagName('head')[0] || body).appendChild(createElement('script')).src = 'http://bdimg.share.baidu.com/static/api/js/share.js?v=89860593.js?cdnversion=' + ~(-new Date() / 36e5)];
        if(window._bd_share_main){
            window._bd_share_main.init();
        }
    };

    /**
     * 日期处理，
     * @param dataStr 需要处理的日期字符串，如果不传递则默认为当前时间
     * @returns {{getCurrentYear: getCurrentYear, getCurrentMonth: getCurrentMonth, renderYears: renderYears, renderMonths: renderMonths}}
     */
    global.tools.date = function (time) {
        var year = 1970;
        var month = 1;
        var day = 1;
        var date = new Date();
        //var reg = new RegExp("-");//火狐不兼容

        if (time) {
            if (typeof time === 'string') {
                date = new Date(time.replace(/-/g, "/"));
            } else if (typeof time === 'number') {
                date = new Date(time);
            }
        }

        year = date.getFullYear();
        month = date.getMonth() + 1;
        day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        var week = date.getDay();

        function getCurrentYear() {
            return year;
        }

        function getCurrentMonth() {
            return month;
        }

        function getDay() {
            return day;
        }

        function getHour() {
            return hours;
        }

        function getDate(separator) {
            return year + separator + month + separator + day;
        }

        function getTime() {
            return (hours < 10 ? ('0' + hours) : hours) + ':' +
                (minutes < 10 ? ('0' + minutes) : minutes) + ':' +
                (seconds < 10 ? ('0' + seconds) : seconds);
        }

        function getWeek() {
            var weekDesc = '星期';

            switch (week) {
                case 1:
                    weekDesc += '一';
                    break;
                case 2:
                    weekDesc += '二';
                    break;
                case 3:
                    weekDesc += '三';
                    break;
                case 4:
                    weekDesc += '四';
                    break;
                case 5:
                    weekDesc += '五';
                    break;
                case 6:
                    weekDesc += '六';
                    break;
                case 0:
                    weekDesc += '日';
                    break;
                default:
                    break;
            }
            return weekDesc;
        }

        /**
         * 渲染select的选项
         * @param select select的选择器
         * @param from 从哪一年开始，不设置默认是1970
         * @param to   到那一年，不设置默认为当前年份
         */
        function renderYears(select, from, to) {
            var options = '';
            var startYear = 1970;
            var endYear = year;
            var $select = $(select);

            if (from && $.isNumeric(from)) {
                startYear = from;
            }
            if (to && $.isNumeric(to)) {
                endYear = to;
            }

            var i = endYear;
            for (i; i >= startYear; i--) {
                options += '<option value="' + i + '">' + i + '年</option>';
            }

            $select.empty();
            $select.append(options);
        }

        /**
         * 渲染select的选项
         * @param select select的选择器
         * @param from 从哪一年开始，不设置默认是1970
         * @param to   到那一年，不设置默认为当前年份
         */
        function renderSpecialYears(select, years) {
            var options = '';
            var $select = $(select);

            var i = 0;
            var length = years.length;
            for (i = 0; i < length; i++) {
                options += '<option value="' + years[i] + '">' + years[i] + '年</option>';
            }

            $select.empty();
            $select.append(options);
        }

        /**
         * 渲染select中月份选项
         * @param select select的选择器
         * @param assignYear 指定那一年，没指定则1-12月
         */
        function renderMonths(select, assignYear) {
            var options = '';
            var startMonth = 1;
            var endMonth = 12;
            var $select = $(select);

            if (assignYear && $.isNumeric(assignYear)) {
                if (year == assignYear) {
                    endMonth = month;
                }
            }

            var i = startMonth;
            for (i; i <= endMonth; i++) {
                options += '<option value="' + i + '">' + i + '月</option>';
            }

            $select.empty();
            $(select).append(options);

            if (assignYear && $.isNumeric(assignYear)) {
                if (year == assignYear) {
                    $select.val(month);
                }
            }
        }

        /**
         * 格式化时间
         * @param format 默认为'yyyy-MM-dd hh:mm'
         * @returns {*}
         */
        function format(format) {
            if(!format) {
                format = 'yyyy-MM-dd hh:mm';
            }
            var time = {
                "M+": month,
                "d+": day,
                "h+": hours,
                "m+": minutes,
                "s+": seconds,
                "q+": Math.floor((month + 2) / 3),
                "S+": date.getMilliseconds()
            };
            if (/(y+)/i.test(format)) {
                format = format.replace(RegExp.$1, (year + '').substr(4 - RegExp.$1.length));
            }
            for (var k in time) {
                if (new RegExp("(" + k + ")").test(format)) {
                    format = format.replace(RegExp.$1, RegExp.$1.length === 1 ?
                        time[k] : ("00" + time[k]).substr(("" + time[k]).length));
                }
            }
            return format;
        }

        return {
            format: format,
            getYear: getCurrentYear,
            getMonth: getCurrentMonth,
            getDay: getDay,
            getTime: getTime,
            getDate: getDate,
            getHour: getHour,
            getWeek: getWeek,
            renderYears: renderYears,
            renderSpecialYears: renderSpecialYears,
            renderMonths: renderMonths
        };
    };

    /**
     * 格式化数字，例如：12300400格式化为12,300,400
     * @param number
     */
    global.tools.formatDigital = function (number) {
        var result = number;

        if (!number || !$.isNumeric(number)) {
            return result;
        }

        var numberStr = number.toString();
        var pointIndex = numberStr.indexOf('.');
        var lastIndex = numberStr.length - 1;

        if (pointIndex >= 0) {
            lastIndex = pointIndex - 1;
        }

        result = '';
        var count = 1;
        for (var i = lastIndex; i >= 0; i--, count++) {
            var temp = numberStr[i];

            result = temp + result;
            if (count % 3 === 0 && i !== 0) {
                result = ',' + result;
            }
        }
        return result;
    };

    /**
     * 初始定位侧边悬浮导航。注意使用时，侧边导航栏高度请设置为auto。
     * @param options
     * {
	 *  wrapper: 外框选择器（包含侧边栏和内容区）
	 *  head：头部，没有则设置为空；
	 *  foot: 底部，没有则设置为空；
	 *  contentStartIndex: 内容列表有效起始索引；
	 *  navStartIndex: 导航栏列表有效起始索引;
	 *  sideNav: 导航栏选择器，例子id:'#nav',class:'.nav'
	 *  sideNavEntry: 导航栏列表选择器，例子li元素：'li',class:'.entry'；（与sideNav是父子关系）
	 *  content: 内容区选择器
	 *  contentEntry: 内容区列表选择器（与content是父子关系）;
	 *  selectClass: 选中的侧边导航栏的选项class，如：'curr';
	 *  currIndex: 设置默认选中的导航栏索引,-1表示不设置；
	 *  scrollAnimate: true则使用动画，false为不使用，默认为使用动画;
	 *  position: 侧边导航栏原始定位方式，absolute(float布局)，relative（非float布局）
	 * }
     */
    global.tools.sideNavInit = function (options) {
        //待检查参数
        var animate = !!options.scrollAnimate || true;
        var headHeight = options.head ? $(options.head).height() : 0;
        var footHeight = options.foot ? $(options.foot).height() : 0;
        var windowHeight = $(window).height();
        var sideNavHeight = windowHeight - headHeight;
        var clickIndex = -1; //导航栏鼠标点击的元素的索引
        var contentStartIndex = options.contentStartIndex;//内容列表起始索引
        var navStartIndex = options.navStartIndex;//导航栏列表起始索引
        var $sideNav = $(options.sideNav);
        var sideNavInitHeight = $sideNav.height();
        var $sideNavLi = $(options.sideNav + ' > ' + options.sideNavEntry);
        var $contentLi = $(options.content + ' > ' + options.contentEntry);
        var selectClass = options.selectClass;
        var defaultIndex = options.currIndex;

        var nav2HeadDistance = $sideNav.offset().top - headHeight;//侧边导航栏到头部的距离
        var scrollDistance = 0;//滚动条滚动的距离

        //设置左边导航的高度,
        if (sideNavHeight > sideNavInitHeight) {
            $sideNav.css("height", sideNavHeight);
        } else {
            sideNavHeight = sideNavInitHeight + footHeight;
            $sideNav.css("height", sideNavHeight);
        }

        /**
         * 窗口自动定位
         */
        function clickPosition() {
            var targetIndex = clickIndex - navStartIndex + contentStartIndex;
            var entryTop = $contentLi.eq(targetIndex).offset().top;

            scrollWindow(entryTop, function () {
                if (clickIndex > -1) {
                    $sideNavLi.eq(clickIndex).addClass(selectClass).siblings('.' + selectClass).removeClass(selectClass);
                    clickIndex = -1;
                }
            });
        }

        /**
         * 滚动窗口
         * @param scrollTop 窗口滚动的距离
         * @param done 滚动完成后的回调
         */
        function scrollWindow(scrollTop, done) {
            if (animate) {
                $('html, body').stop(true).animate({
                    scrollTop: scrollTop - headHeight
                }, {
                    duration: 100,
                    always: done //动画不管完没完成总是会执行这个回调
                });
            } else {
                //不使用动画
                $('html, body').scrollTop(scrollTop - headHeight);
                done();
            }
        }

        /**
         * 图片加载过程中，定位校正
         */
        function regulatePosition() {
            var selectedNavIndex = $sideNav.find('.' + selectClass).index();
            var length = $contentLi.length;
            var absIndex = selectedNavIndex - navStartIndex;
            var sideNavTop = $sideNav.offset().top;
            var mistake = 5;//误差范围，浏览器滚动一次长度不一致

            var currContentTop = $contentLi.eq(absIndex + contentStartIndex).offset().top;
            var nextContentTop = absIndex < length - 1 ? $contentLi.eq(absIndex + 1 + contentStartIndex).offset().top : 99999;

            if (sideNavTop >= currContentTop - mistake &&
                sideNavTop < nextContentTop - mistake) {

                return;
            }
            scrollWindow(currContentTop, function () {
                $sideNavLi.eq(selectedNavIndex).addClass(selectClass).siblings('.' + selectClass).removeClass(selectClass);
            });
        }

        //导航栏点击事件
        $sideNav.on("click", options.sideNavEntry, function () {
            clickIndex = $(this).index();

            //如果点击的导航栏条目的index小于有效的导航栏起始index则不处理，即不是有效的栏目就不处理事件
            if (clickIndex < navStartIndex) {
                return;
            }

            //自动对齐导航栏和内容
            clickPosition();
        });

        //窗口大小变化事件，动态修改侧边栏位置
        $(window).on('resize', function () {
            //如果侧边导航栏是处于悬浮状态则动态修改位置
            if ($sideNav.css('position') === 'fixed') {
                var left = $(options.wrapper).offset().left;
                $sideNav.css({left: left + 'px'});
            }
        });

        $(window).on("scroll", function () {
            scrollDistance = $(document).scrollTop();
            //如果窗口的滚动距离大于了侧边导航栏到头部的距离则悬浮侧边导航栏
            if (scrollDistance > nav2HeadDistance) {
                var left = $(options.wrapper).offset().left;
                var footTop = footHeight === 0 ? $('html').height() : $(options.foot).offset().top;
                //左侧导航栏数据区域的底部抵达foot的时候，如果用户继续往下滚动则保持左侧导航栏数据区底部与foot相切
                var distance = (scrollDistance + headHeight + sideNavInitHeight) - footTop;
                if (distance > 0) {
                    $sideNav.css({position: "fixed", top: (headHeight - distance) + "px", left: left + 'px'});
                } else {
                    $sideNav.css({position: "fixed", top: headHeight + "px", left: left + 'px'});
                }
            } else {
                if ($sideNav.css('position') !== 'absolute') {
                    $sideNav.css({position: 'absolute', top: "0", left: '0'});
                }
            }

            //根据窗口滚动情况动态设置左边导航栏的选中项
            autoSelectNav();
        });

        /**
         * 左侧导航栏根据窗口滚动情况自动选择选项
         */
        function autoSelectNav() {
            var length = $contentLi.length;
            var sideNavTop = $sideNav.offset().top;
            var mistake = 5;//误差范围，浏览器滚动一次长度不一致

            //当左侧导航栏的位置在右边内容列表的某个条目内，则设置侧边导航栏选中该条目对应的选项（通过index对应）
            for (var i = 0; i < length; i++) {
                var currContentTop = $contentLi.eq(i + contentStartIndex).offset().top;
                var nextContentTop = i < length - 1 ? $contentLi.eq(i + 1 + contentStartIndex).offset().top : 99999;

                if (sideNavTop >= currContentTop - mistake &&
                    sideNavTop < nextContentTop - mistake) {

                    if (clickIndex > -1 && clickIndex !== i + navStartIndex) {
                        return;
                    }

                    $sideNavLi.eq(i + navStartIndex).addClass(selectClass).siblings('.' + selectClass).removeClass(selectClass);
                    break;
                }
            }
        }

        /**
         * 图片加载过程中校正被影响的定位。每加载完一张就校正定位（待优化为每正在加载一张就校正定位，难度较大）。
         * 此方法使用在进入页面就有默认定位时，如果没有默认定位则不必调用。
         */
        function perImgLoadPosition() {
            $contentLi.find('img').each(function (index) {
                //之前用deferred是想到等所有图片加载完后才校正位置；
                //后来经过优化每张图片加载完成就校正，发现deferred用在这儿就已经失去了意义
                //var deferred = $.Deferred();
                //$(this).load(deferred.resolve);
                //
                //image.push(deferred);
                //$.when(image[index]).done(function () {
                //	regulatePosition();
                //});

                $(this).load(regulatePosition);
            });
        }

        //刚进入页面，如果设置了默认定位则定位
        if (defaultIndex >= 0) {
            $sideNavLi.eq(defaultIndex).click();
            perImgLoadPosition();
        }
    };
    /**
     * 地图获取
     * @param path 路径
     * @param todo 回调函数
     */
    tools.getMap =	function (path, todo) {
        $.get(path, function (mapJson) {
            todo(mapJson);
        });
    };

    /**
     * 使用方法：
     *  请用在页面渲染后，且需要聚焦效果的元素要加上class：wait-focus
     */
    global.tools.focus = function() {
        $('body .wait-focus').each(function(index, input){
            var $input = $(input);
            var defaultValue = $input.val();

            $input.on('focus', function() {
                var $this = $(this);
                $this.addClass("compl-border");

                var value = $this.val();
                if(defaultValue === value) {
                    $this.val('');
                }
            });

            $input.on('blur', function () {
                var $this = $(this);
                $this.removeClass("compl-border");

                var value = $this.val();
                if(value.trim() === '') {
                    $this.val(defaultValue);
                }
            });
        });
    };

    /**
     * 图片设置，暂时不使用，待修改
     * @param option
     */
    global.tools.setImg = function(option) {
        $(option.selector + ' img').each(function() {
            var $img = $(this);
            var pHeight = $img.parents('a').eq(0).height();
            var pWidth = $img.parents('a').eq(0).height();

            (function () {
                var list = [], intervalId = null,
                // 用来执行队列
                    tick = function () {
                        var i = 0;
                        for (; i < list.length; i++) {
                            list[i].end ? list.splice(i--, 1) : list[i]();
                        }
                        !list.length && stop();
                    },
                // 停止所有定时器队列
                    stop = function () {
                        clearInterval(intervalId);
                        intervalId = null;
                    };
                return function (url, ready, load, error) {
                    var onready, width, height, newWidth, newHeight,
                        img = new Image();
                    img.src = url;
                    // 如果图片被缓存，则直接返回缓存数据
                    if (img.complete) {
                        ready.call(img);
                        load && load.call(img);
                        return;
                    }
                    width = img.width;
                    height = img.height;
                    // 加载错误后的事件
                    img.onerror = function () {
                        error && error.call(img);
                        onready.end = true;
                        img = img.onload = img.onerror = null;
                    };
                    // 图片尺寸就绪
                    onready = function () {
                        newWidth = img.width;
                        newHeight = img.height;
                        if (newWidth !== width || newHeight !== height || newWidth * newHeight > 1024) {
                            ready.call(img);
                            onready.end = true;
                        }
                    };
                    onready();
                    // 完全加载完毕的事件
                    img.onload = function () {
                        // onload在定时器时间差范围内可能比onready快
                        // 这里进行检查并保证onready优先执行
                        !onready.end && onready();
                        load && load.call(img);
                        // IE gif动画会循环执行onload，置空onload即可
                        img = img.onload = img.onerror = null;
                    };
                    // 加入队列中定期执行
                    if (!onready.end) {
                        list.push(onready);
                        // 无论何时只允许出现一个定时器，减少浏览器性能损耗
                        if (intervalId === null) intervalId = setInterval(tick, 40);
                    }
                };
            })()($img.attr('src'),
                function() {
                }, function() {

                    if( this.width / this.height > pWidth / pHeight ) {
                        $img.css({
                            position: 'relative',
                            width: '100%',
                            height: 'auto'
                        });

                        $img.css({
                            top: '50%',
                            marginTop: '-' + $img.height()/2 + 'px'
                        });
                    } else if(this.width / this.height < pWidth / pHeight ) {
                        $img.css({
                            position: 'relative',
                            width: 'auto',
                            height: '100%'
                        });

                        $img.css({
                            left: '50%',
                            marginLeft: '-' + $img.height()/2 + 'px'
                        });
                    }
                },function() {
                });
        });
    };
    /**
     * 使用方法：
     *  字段的验证，支持非空、手机、邮箱的判断
     */
    global.tools.validate = function(value, type, minlength, maxlength) {
        var value = $.trim(value);
        // 非空验证
        if ('require' === type) {
            return !!value;
        }
        // 手机号验证
        if ('phone' === type) {
            return /^1\d{10}$/.test(value);
        }
        // 邮箱格式验证
        if ('email' === type) {
            return /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/.test(value);
        }
        //验证长度
        if ('length' === type) {
            if (value.length <= minlength || value.length >= maxlength) {
                return false;
            } else {
                return true;
            }
        }
        //汉字和字母验证规则
        if ('chinese' === type) {
            return /^([\u4E00-\uFA29]*[a-z]*[A-Z]*)+$/.test(value);
        }
    };
})(window, jQuery);

(function (global, $, undefined) {
    global.tools = global.tools || {};

    /**
     * 视频弹出层
     * @param option
     * {
     *  list: 视频列表选择器，默认为‘.video-lst’
     *  entry: 列表条目选择器，默认为‘li’
     *  title: 视频名称选择器，默认为‘.video-h3’
     *  video: 视频地址属性名(条目中的属性)，默认为‘data-src’
     *  img: 图片选择器，默认为‘img.scale-pic’
     *  play: 播放，默认为'.play-video'
     * }
     * @param played 点击播放后的回调事件
     */
    global.tools.popupVideo = function (option, played) {
        var list = option.list || '.video-lst';
        var entryElement = option.entry || 'li';
        var titleElement = option.title || '.video-h3';
        var imageElement = option.img || 'img.scale-pic';
        var videoAttr = option.video || 'data-src';
        var playClass = option.play || '.playVideo';

        function closeDIV() {
            $(".popup-video").remove();
        }

        $('body').on('click', '.popup-video .pop-remove', function () {
            closeDIV();
        });

        $(list).on("click", playClass, function (e) {
            var $entry = $(this).parents(entryElement).eq(0);
            var vedio_src = $entry.attr(videoAttr);
            var imgSrc = $entry.find(imageElement).attr("src");
            var tit = $entry.find(titleElement).html();

            var layer = '<div class="popup-video"><div class="opa80 pop-remove"></div>' +
                '<a class="pop-close pop-remove"></a>' +
                '<div class="video-box">' +
                '<div class="video-cont">' +
                '<div id="vedio" style="opacity:0; filter:alpha(opacity=0); -webkit-opacity:0; -moz-opacity:0;"></div>' +
                '<div class="vpro"></div>' +
                '</div>' +
                '<div class="video-tit">' +
                '<span>' + tit + '</span>' +
                // '<p><a class="share-video bds-more bdsharebuttonbox bdshare-button-style0-16" title="" data-cmd="more" style="position: absolute;top: 0; z-index:99; margin:0;padding:0;right: 0;width:32px; height:32px; display:inline-block; " data-bd-bind="1452487568673"></a></p>' +
                '</div>' +
                '</div>' +
                '</div>';

            $(document.body).append(layer);

            var _wid = $(".video-cont").width();
            var _hei = $(".video-cont").height();
            var s1 = new SWFObject("./flvplayer.swf", "single", _wid, _hei, "7");
            s1.addParam("allowfullscreen", "true");
            s1.addParam("wmode", "opaque");
            s1.addVariable("autostart", "true");	//打开时自动播放
            s1.addVariable("backcolor", "0x000000");
            s1.addVariable("frontcolor", "0xCCCCCC");
            s1.addVariable("file", vedio_src);
            s1.addVariable("image", imgSrc);	//封面图片的调用
            s1.addVariable("width", _wid);
            s1.addVariable("height", _hei);
            s1.write("vedio");
            $(".opa80").animate({opacity: 1}, 200);
            setTimeout(function () {
                $(".pop-close").animate({opacity: 1}, 200)
            }, 100);
            setTimeout(function () {
                $(".video-box").animate({opacity: 1}, 200)
            }, 100);
            setTimeout(function () {
                $(".vpro").animate({left: 0}, 500).fadeOut();
            }, 200);
            setTimeout(function () {
                $("#vedio").animate({opacity: 1}, 500)
            }, 800);

            if(played && typeof played === 'function') {
                played($entry);
            }
        });
    };
})(window, jQuery);


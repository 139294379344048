/* Created by 何小贵 on 2018/8/28.
 */
(function() {
    /**
     * @param  {[String]}       url
     * @param  {[Bolean]}       type // 是否是电商接口
     * @param  {object}         params // 是否是电商接口
     * @param  {[Object]}       template  // 模板对象
     * @param  {[Object]}      noDataTmpl // 没有数据加载的模板  默认添加暂无数据提示
     * @param  {[Bolean]}       pagination // 是否分页 默认不分页
     * @param  {[Number]}       size   //每页条数
     * @param  {[Number]}       curr  // 请求的第几页
     * @param  {function}       callback  // data处理 可不传
     * @param  {function}       complete  // 加载完页面之后的执行方法
     * @param  {function}       error  // 请求出错的执行的方法
     */
    function RoolLoading (element, config) {
        this.ele = element;
        this.nodataTxt = '暂无数据！';
        this.refreshTxt = '获取服务器数据失败，请尝试刷新浏览器页面！';
        this.isScroll = false;
        this.isFirstLoad = true;
        this.config = {
            url: '',
            type: false,
            template: {},
            total: 0, // 总页数
            size: 1, // 单页数量
            curr: 1, // 当前页
            page: 1, // 初始请求第几页
            columnWidth: 0, // 单个宽度
            waterFallEle: '',
            padding: 0,
            loading: false,
            params: {}
            // callback: function(res) {},
            // complete: function(res) {},
            // error: function(res){}
        };
        // 默认参数扩展
        if (config && $.isPlainObject(config)) {
            $.extend(this.config, config);
        }
    }
    RoolLoading.prototype = {
        /**
         * 初始化
         */
        init: function() {
            var _this = this,
                config = _this.config;
            config.params.page = config.curr;
            config.params.limitPage = config.size;

            _this.isScroll = false;

            //计算高度
            _this.arrHeight = [];
            var width = _this.ele.outerWidth(true);
            //计算每行最多放多少列
            if(config.columnWidth){
                _this.num = Math.floor(width / config.columnWidth);
                _this.margin = Math.floor((width - _this.num * config.columnWidth) / (_this.num - 1));
                config.columnWidth = config.columnWidth - config.padding * 2;
            }

            _this.loadRender(config.params);
            _this.scrollEvent(); //滚动加载事件判断
        },
        /**
         * [getMinHeight 获取最小高度的下标]
         * @param  {[Object]} arr [_this.arrHeight]
         * @return {Number} [最小高度的下标]
         */
        getMinHeight: function(arr) {
            var minHeight = Math.min.apply(null, arr);
            for (var i in arr) {
                if (arr[i] === minHeight) {
                    return i;
                }
            }
        },
        // 滚动事件
        scrollEvent: function(){
            var _this = this;
            var scrollTimer;
            $(window).off("scroll").on("scroll",function(){
                scrollTimer = setTimeout(scrollFun,100);
            });
            function scrollFun() {
                clearTimeout(scrollTimer);
                var scrollTop = $(this).scrollTop();    //滚动条距离顶部的高度
                var scrollHeight = $(document).height();   //当前页面的总高度
                var clientHeight = $(this).height();    //当前可视的页面高度
                if( scrollHeight <= scrollTop + clientHeight + 100){   //距离顶部+当前高度 >=文档总高度 即代表滑动到底部
                    if(!_this.isScroll){
                        $(window).off("scroll");
                        return false;
                    }
                    _this.config.curr++;
                    var config = _this.config;
                    if(config.curr <= config.total) {
                        config.params.page = config.curr;
                        config.params.limitPage = config.size;
                        _this.loadRender(config.params);
                    } else {
                        _this.addNoMoreData();
                    }
                    _this.isScroll=false;
                }
            }
        },
        addNoMoreData: function(){
            var _this = this;
            if(_this.ele.is('ul')){
                _this.ele.append('<li class="no-more">已经到底了</li>');
            } else {
                _this.ele.append('<div class="no-more">已经到底了</div>');
            }
        },
        /**
         * 加载模版
         * @param  {[Object]}  params
         * @return {[Undefind]}
         */
        loadRender: function(params) {
            var _this = this,
                config = _this.config;
            var questParam = {
                url: config.url,
                type: config.type,
                data: params
            };
            if(config.params.page === 1) {
                _this.ele.empty();
                _this.ele.prop('style', '');
            }
            if(config.loading) {
                var loading = tools.loading(_this.ele, 'after');
            }
            tools.request(questParam, function(res){
                if(config.loading) {
                    loading.clean();
                }
                if (res.code === 0) {
                    var data = res.datas || res.data;
                    if (config.callback && typeof config.callback === 'function') {
                        data = config.callback(data);
                    }
                    if (res.page) {
                        if( res.page.currPage < res.page.totalPage) {
                            _this.isScroll = true;
                        } else {
                            _this.isScroll = false;
                            _this.addNoMoreData();
                        }
                        _this.config.curr = res.page.currPage;
                        _this.config.size = res.page.pageSize;
                        _this.config.total = res.page.totalPage;
                    }
                    //是数组
                    if ($.isArray(data)) {
                        if (!data.length) {
                            if(_this.ele.is('ul')){
                                _this.ele.append('<li class="no-data">'+ _this.nodataTxt +'</li>');
                            } else {
                                if(config.noDataTmpl) {
                                    config.noDataTmpl.tmpl().appendTo(_this.ele);
                                } else {
                                    _this.ele.append('<div class="no-data">'+ _this.nodataTxt +'</div>');
                                }
                            }
                        } else {
                            config.template.tmpl({list: data}).appendTo(_this.ele);
                        }
                    } else { // 不是数组
                        config.template.tmpl({data: data}).appendTo(_this.ele);
                    }
                    if(_this.config.waterFallEle){
                        setTimeout(function(){
                            _this.waterFall();
                        });
                    }
                    config.complete && config.complete(res);
                } else if (res.code === 2) {
                    tools.doLogin();
                } else {
                    config.error && config.error();
                    if(_this.ele.is('ul')){
                        _this.ele.append('<li class="no-data">'+ res.message +'</li>');
                    } else {
                        _this.ele.append('<div class="no-data">'+ res.message +'</div>');
                    }
                }
            });
        },
        waterFall: function(){
            var _this = this;
            //获取列表
            var $items = _this.ele.find(_this.config.waterFallEle).fadeIn(500);
            var config = _this.config;
            //遍历列表
            $items.each(function(index, dom) {
                if (index < _this.num) {
                    _this.arrHeight[index] = ($(dom).outerHeight(true));
                    //添加样式
                    $(dom).css({
                        'width': config.columnWidth,
                        'left': config.columnWidth * index + _this.margin * (index % _this.num) + config.padding * 2 * (index % _this.num),
                        'top': 0
                    });
                } else {
                    //计算最小高的下标
                    var min = _this.getMinHeight(_this.arrHeight);
                    //添加样式
                    $(dom).css({
                        'width': config.columnWidth,
                        'left': config.columnWidth * min + _this.margin * (min % _this.num) + config.padding * 2 * (index % _this.num),
                        'top': _this.arrHeight[min]
                    });
                    _this.arrHeight[min] += $(dom).outerHeight(true);
                };
            });
            //填充盒子的高度
            _this.ele.css('padding-top', Math.max.apply(null, _this.arrHeight) + 20);
        }
    };
    // 添加到window对象上
    window.RoolLoading = RoolLoading;
    //封装到jquery对象上去
    $.fn.roolLoading = function(config) {
        var roolLoading = new RoolLoading(this, config);
        return roolLoading.init();
    }
})(window.jQuery || $);

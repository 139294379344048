/**
 * @authors hexg
 * @date    2017-12-06 09:08:56
 */



var today = new Date();
/**
 * payCalendar 日历带价格插件
 * @param    url      {url}           请求的链接
 * @param    date     {string||array} 初始时间 string格式：2017-1-10 array格式：['2017-1-10','2017-1-10'] 起止时间
 * @param  	minDate   {string}        拼组团请求日历的时候 最小的可选择时间  '2017-1-10'
 * @param  	maxDate   {string}        拼组团请求日历的时候 最大的可选择时间  '2017-1-10'
 * @param    mode     {string}        日历插件类型 single或者range
 * @param    data     {object}        请求附带的参数
 * @param    change   {function}      插件选中值后的回调
 * @return
 */
window.payCalendar = (function(){
    var calendar = {
        init: function(trigger, config) {
            this.trigger = $(trigger);
            this.date;
            //配置
            this.config = {
                url: '',
                eventType:'click',
                mode:'single',
                date: '',
                data: {},
                minDate: '',
                maxDate: '',
                change: function(params) {}
            };
            // 默认参数扩展
            if (config && $.isPlainObject(config)) {
                $.extend(this.config, config, true);
            };
            this.date = this.config.date;
            var date;
            if(typeof this.config.date == 'string'){
                date = this.config.date ? this.config.date.split('-') : '';

            } else if(typeof this.config.date == 'object') {
                date = this.config.date[0] ? this.config.date[0].split('-') : '';
                this.startDate = this.date[0];
                this.rangeEndDate = this.date[1];
            }

            if(this.config.minDate){
                today = new Date(this.config.minDate);
            }

            if(date.length === 3){
                this.year = parseInt(date[0]);
                this.month = parseInt(date[1]);
                this.day =parseInt(date[2]);
            }else{
                this.year = today.getFullYear();
                this.month = today.getMonth() + 1;
            }
            this.config.data.month = this.year + '-' + this.formatNumber(this.month);
            if(this.config.maxDate){
                this.endDate = this.config.maxDate.split('-');
            }
            this.eventBind();
        },
        eventBind:function(){
            var config = this.config;
            var _self = this;
            //this.trigger.on(config.eventType,popCalendar);
            popCalendar();
            function popCalendar(){
                $("html").addClass("no-scroll");
                // 渲染dom
                _self.payCalendar = $('<div class="payCalendar"></div>');
                // _self.calendarMask = $('<p class="ui-calendar-mask"></p>');
                _self.calendar = $('<div class="ui-calendar"></div>');

                var calendarHtml = '<div class="ui-calendar-hd">' +
                    '<span class="prev"><i class="daq-icon">&#xe6b2;</i>上一月</span>' +
                    '<h2 class="title">2017年11月</h2>' +
                    '<span class="next">下一月<i class="daq-icon">&#xe6b1;</i></span>' +
                    '</div>' +
                    '<div class="ui-calendar-bd">' +
                    '<div class="ui-calendar-bd-bg"></div>' +
                    '<table>' +
                    '<thead>' +
                    '<tr><th>日</th><th>一</th><th>二</th><th>三</th><th>四</th><th>五</th><th>六</th></tr>' +
                    '</thead>' +
                    '<tbody>' +
                    '<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>' +
                    '<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>' +
                    '<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>' +
                    '<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>' +
                    '<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>' +
                    '<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>' +
                    '</tbody>' +
                    '</table>' +
                    '</div>';
                _self.calendar.html(calendarHtml);
                _self.payCalendar.append(_self.calendar);

                //$("body").append(_self.payCalendar);
                $(_self.trigger).html(_self.payCalendar);
                //_self.calendarMask.on("click",_self.hide);
                _self.btnClick();

                if (_self.year === today.getFullYear() && _self.month === (today.getMonth() + 1)) {
                    _self.calendar.find('.prev').addClass('disabled');
                };
                _self.loadCalendar(_self.config);
                _self.formatter();
            }
        },
        // 格式化数字
        formatNumber:function(n) {
            n = parseInt(n);
            return n < 10 ? "0" + n : n;
        },
        //获取最大值到最小值的数组
        initYears:function(max, min) {
            var arr = [];
            for (var i = (min || 1950); i <= (max || 2030); i++) {
                arr.push(i);
            }
            return arr;
        },
        //获取天数
        getDays:function(max) {
            var days = [];
            for (var i = 1; i <= (max || 31); i++) {
                days.push(i < 10 ? "0" + i : i);
            };
            return days;
        },
        //根据月年获取天数
        getDaysByMonthAndYear:function(month, year) {
            var int_d = new Date(year, parseInt(month) + 1 - 1, 1),
                _self = this;
            var d = new Date(int_d - 1);
            return _self.getDays(d.getDate());
        },
        //格式化数字
        formatNumber:function(n) {
            n = parseInt(n);
            return n < 10 ? "0" + n : n;
        },
        //获取星期几
        getWeek: function() {
            return new Date(this.year,this.month-0-1,'01').getDay();
        },
        //上一页
        prev: function() {
            var _self = this;
            if (_self.calendar.find('.next').hasClass('disabled')) {
                _self.calendar.find('.next').removeClass('disabled');
            };
            if (_self.calendar.find('.prev').hasClass('disabled')) {
                return false;
            };
            if (_self.month <= 1) {
                _self.year--;
                _self.month = 12;
            } else {
                _self.month--;
            }
            _self.config.data.month = _self.year + '-' + _self.formatNumber(_self.month);
            _self.loadCalendar(_self.config);
            _self.formatter();

            if (_self.year === today.getFullYear() && _self.month === (today.getMonth() + 1)) {
                _self.calendar.find('.prev').addClass('disabled');
            };
        },
        //下一页
        next: function() {
            var _self = this;
            if (_self.calendar.find('.prev').hasClass('disabled')) {
                _self.calendar.find('.prev').removeClass('disabled');
            };
            if(_self.calendar.find('.next').hasClass('disabled')){
                return false;
            };
            if (_self.month >= 12) {
                _self.year++;
                _self.month = 1;
            } else {
                _self.month++;
            }
            _self.config.data.month = _self.year + '-' + _self.formatNumber(_self.month);
            _self.loadCalendar(_self.config);
            _self.formatter();

            if(_self.year === parseInt(_self.endDate[0]) && _self.month === parseInt(_self.endDate[1])){
                _self.calendar.find('.next').addClass('disabled');
            };
        },
        //格式化
        formatter: function() {
            var _self = this;
            _self.payCalendar.find(".title").html(_self.year + '年' + _self.formatNumber(_self.month) + '月');
        },
        //加载数据
        loadCalendar: function(param) {
            var $calendar = this.calendar,
                date =param.data.month.split("-");
            _self = this,
                // start = _self.getWeek() === 0 ? 0 : _self.getWeek() - 1,
                $td = $calendar.find('td');
            $td.html('');
            var rp = {
                url: _self.config.url,
                data: param.data
            }
            tools.request(rp, function (res) {
                if(!_self.endDate) {
                    _self.endDate = res.data.maxDate.split('-');
                }
                var start;
                if(res.data.hasOwnProperty("dataPrices")){
                    start = res.data.dataPrices.first - 1;
                    res= res.data.dataPrices.datePrice;
                } else if(res.data.hasOwnProperty("datePrice")){
                    start = res.data.first - 1;
                    res = res.data.datePrice;
                }
                // start = start === 0 ? start : start -1;
                $calendar.find("td").removeClass("active").removeClass('on');
                _self.removeAttribute();

                var $tds;
                if(start > 0) {
                    start-- ;
                    $tds = $calendar.find('td:gt(' + start + ')');
                } else {
                    $tds = $calendar.find('td');
                }
                $tds.each(function(index, value) {
                    if (index >= res.length) {
                        return false;
                    }

                    $(this).addClass('bg-gray').html('<span class="date">' + res[index]['date'] + '</span><span class="price">' + (res[index].price != -1 ? '￥' + res[index].price : '') + '</span>');
                    if (res[index].price != -1) {
                        $(this).addClass('on').attr('data-price', res[index].price);
                        $(this).attr('data-maxbuy', res[index].maxBuyNum);
                        $(this).attr('data-minbuy', res[index].minBuyNum);
                        $(this).attr('data-getdate', _self.formatNumber(index+1));
                        $(this).attr('data-month',date[1]);
                        $(this).attr('data-year',date[0]);
                        $(this).attr("data-stock",res[index].stock);
                        $(this).attr("data-childPrice",res[index].childPrice);
                    } else {
                        $(this).addClass('bg-gray')
                    }
                });
                _self.addActive();
            })
        },
        // 清除标td标签上的自定义属性
        removeAttribute: function(){
            this.calendar.find("td").each(function(index,el) {
                $(this).removeAttr('data-price');
                $(this).removeAttr('data-maxbuy');
                $(this).removeAttr('data-minbuy');
                $(this).removeAttr('data-getdate');
                $(this).removeAttr('data-month');
                $(this).removeAttr('data-year');
                $(this).removeAttr("data-stock");
                $(this).removeAttr("data-childPrice");
            });
        },
        // 日历销毁
        hide: function(){
            $("html").removeClass("no-scroll");
            $('.payCalendar').remove();
            this.payCalendar=null;
            this.calendarMask=null;
            this.calendar = null;
        },
        // 日历按钮点击事件
        btnClick:function(){
            this.clicknum = 0;
            var _self = this;
            //下一月
            _self.payCalendar.find('.ui-calendar .next').on('click', function() {
                if(_self.year === parseInt(_self.endDate[0]) && _self.month === parseInt(_self.endDate[1])){
                    $(this).addClass('disabled')
                    return false;
                };
                if($(this).is('.disabled')){
                    return false;
                }
                _self.next();
            });
            //上一月
            _self.payCalendar.find('.ui-calendar .prev').on('click', function() {
                _self.prev();
            });
            // 选择日期
            _self.payCalendar.on('click','.ui-calendar td.on', function() {
                var $this = $(this);
                if(_self.config.mode=='single'){
                    _self.singleOnchage($this);
                } else if(_self.config.mode == 'range'){
                    _self.rangeOnchage($this);
                }
            });
        },
        // 单独日期选择
        singleOnchage:function($el){
            var _self = this;
            _self.payCalendar.find('.ui-calendar td').removeClass('active');
            $el.addClass('active');
            _self.day =  $el.data('getdate');
            _self.month = _self.month > 10 ? _self.month : '0' + parseInt(_self.month)
            _self.date = _self.year + '-' + _self.month + '-' + _self.day;
            typeof _self.config.change === 'function' && _self.config.change({
                year: _self.year,
                month: _self.month,
                price: $el.attr('data-price'),
                minBuy: $el.attr('data-minbuy'),
                maxBuy: $el.attr('data-maxbuy'),
                getDate: $el.attr('data-getdate'),
                stock:$el.attr("data-stock"),
                date:_self.date,
                childPrice:$el.attr("data-childPrice")
            });
            //_self.hide();
        },
        // 日期段选择
        rangeOnchage:function($el) {
            var _self = this;
            var date = $el.attr("data-year") + '-' + $el.attr("data-month") +'-' +$el.attr("data-getdate");
            if(_self.clicknum ==0){
                $td = _self.calendar.find("td");
                $td.removeClass("active");
                $el.addClass("active");
                _self.rangeStart = $el;
                _self.startDate = date;
                $el.attr("data-date",date);
                _self.clicknum ++;
            } if(_self.clicknum ==1 && !$el.is(".active")){
                var dateFlag = _self.compareDate(_self.startDate,date);
                if(dateFlag){
                    _self.rangeEndDate = _self.rangeStart.attr("data-date");
                    _self.startDate = date;
                    _self.rangeStart = $el;
                }else {
                    _self.rangeEndDate = date;
                }
                _self.date = [_self.startDate,_self.rangeEndDate];

                typeof _self.config.change === 'function' && _self.config.change({
                    price: _self.rangeStart.attr('data-price'),
                    minBuy: _self.rangeStart.attr('data-minbuy'),
                    maxBuy: _self.rangeStart.attr('data-maxbuy'),
                    stock:_self.rangeStart.attr("data-stock"),
                    date:_self.date,
                    startDate:_self.startDate,
                    endDate:_self.rangeEndDate,
                    childPrice:_self.rangeStart.attr("data-childPrice")
                });

                _self.clicknum = 0; //重置点击计数器
                //_self.hide();
            }
        },
        /**
         * [compareDate 比较日期大小]
         * @param  {string} date1 [日期1]
         * @param  {string} date2 [日期2]
         * @return {boolen}       date1>date2 ? true :false
         */
        compareDate:function(date1,date2){
            var time1 = new Date(date1.replace(/\-/g, "\/")).getTime();
            var time2 = new Date(date2.replace(/\-/g, "\/")).getTime();
            if(time1 > time2){
                return true;
            }else{
                return false;
            }
        },
        // 初始化日历时 为日历对应日期添加选中状态
        addActive:function() {
            var _self = this;
            var $td = _self.calendar.find("td");
            if(_self.config.mode == 'single') {
                var selectDate = _self.date.split("-");
                var selectDay = selectDate[2],
                    selcetMon = selectDate[1],
                    selectYear =selectDate[0];
                $td.each(function(index, el) {
                    if($(this).attr('data-getdate')-0 === selectDay-0 && selcetMon-0 === $(this).attr('data-month')-0 && selectYear-0 === $(this).attr('data-year')-0){
                        $(this).addClass("active");
                        return;
                    }
                });
                return;
            }  else if(_self.config.mode == 'range') {
                var startDate = _self.startDate.split("-");
                var	endDate = _self.rangeEndDate.split("-");
                var startDay = parseInt(startDate[2]),
                    startMon = parseInt(startDate[1]),
                    startYear =parseInt(startDate[0]),
                    endDay = parseInt(endDate[2]),
                    endMon = parseInt(endDate[1]),
                    endYear =parseInt(endDate[0]);
                $td.each(function(index, el) {
                    if( $(this).attr('data-year')/1-startYear >= 0 && endYear- $(this).attr('data-year')/1 >= 0){
                        if( $(this).attr('data-month')/1-startMon >= 0 && endMon- $(this).attr('data-month')/1 >= 0){
                            if( $(this).attr('data-getdate')/1-startDay >= 0 && endDay- $(this).attr('data-getdate')/1 >= 0){
                                $(this).addClass("active");
                            }
                        }
                    }

                });


            }

        }

    };
    return calendar;
})();
/**
 * Created by 龚小虎 on 2018/8/21.
 */

/**
 * Created by 何小贵 on 2018/8/13.
 */
(function() {
    /**
     * @param  {[String]} 		url
     * @param  {[Bolean]}		type // 是否是电商接口
     * @param  {object}		    params // 是否是电商接口
     * @param  {[Object]}		template  // 模板对象
     * @param  {[Object]}      noDataTmpl // 没有数据加载的模板  默认添加暂无数据提示
     * @param  {[Bolean]}		pagination // 是否分页 默认不分页
     * @param  {[Number]}		size   //每页条数
     * @param  {[Number]}		curr  // 请求的第几页
     * @param  {function}		callback  // data处理 可不传
     * @param  {function}		complete  // 加载完页面之后的执行方法
     * @param  {function}		error  // 请求出错的执行的方法
     */
    function RenderHtml(element, config) {
        this.ele = element;
        this.nodataTxt = '暂无数据！';
        this.refreshTxt = '获取服务器数据失败，请尝试刷新浏览器页面！';
        this.config = {
            url: '',
            type: false,
            template: {},
            pagination: false,
            total: 0, // 总页数
            size: 1, // 单页数量
            curr: 1, // 当前页
            page: 1, // 初始请求第几页
            loading: false,
            params: {}
            //callback: function(res) {},
            //complete: function(res) {},
            //error: function(res){}
        };
        // 默认参数扩展
        if (config && $.isPlainObject(config)) {
            $.extend(this.config, config);
        }
    }
    RenderHtml.prototype = {
        /**
         * 初始化
         */
        init: function() {
            var _this = this,
                config = _this.config;
            if (config.pagination) {
                _this.page = $('<div class="page js_page"></div>');
                //判断是否有分页
                if ($('.js_page').length >= 1) {
                    $('.js_page').remove();
                }
                _this.ele.after(_this.page);
                _this.flag = true;
                config.params.page = config.curr;
                config.params.limitPage = config.size;
                _this.loadRender(config.params);
            } else {
                config.params.page = config.curr;
                config.params.limitPage = config.size;
                _this.loadRender(config.params);
            }
        },
        /**
         * 加载模版
         * @param  {[Object]}  params
         * @return {[Undefind]}
         */
        loadRender: function(params) {
            var _this = this,
                config = _this.config;
            _this.ele.empty();
            var questParam = {
                url: config.url,
                type: config.type,
                data: params
            };
            if(config.loading) {
                var loading = tools.loading(_this.ele);
            }
            tools.request(questParam, function(res){

                if(config.loading) {
                    loading.clean();
                }
                if (res.code === 0) {
                    var data = res.datas || res.data;
                    if (config.callback && typeof config.callback === 'function') {
                        data = config.callback(data);
                    }
                    if (res.page && config.pagination) {
                        _this.config.curr = res.page.currPage;
                        _this.config.size = res.page.pageSize;
                        _this.config.total = res.page.totalPage;
                        _this.loadPage();
                    }
                    //是数组
                    if ($.isArray(data)) {
                        if (!data.length) {
                            if(_this.ele.is('ul')){
                                _this.ele.append('<li class="no-data">'+ _this.nodataTxt +'</li>');
                            } else {
                                if(config.noDataTmpl) {
                                    config.noDataTmpl.tmpl().appendTo(_this.ele);
                                } else {
                                    _this.ele.append('<div class="no-data">'+ _this.nodataTxt +'</div>');
                                }
                            }
                        } else {
                            config.template.tmpl({list: data}).appendTo(_this.ele);
                        }
                    } else { // 不是数组
                        if(res.page){
                            config.template.tmpl({data: data, total:res.page.total}).appendTo(_this.ele);
                        }else{
                            config.template.tmpl({data: data}).appendTo(_this.ele);
                        }
                        console.log(data, 'tt')
                    }
                    setTimeout(function(){
                        config.complete && config.complete(res);
                    });
                } else if (res.code === 2) {
                    tools.doLogin();
                } else {
                    config.error && config.error();
                    if(_this.ele.is('ul')){
                        _this.ele.append('<li class="no-data">'+ res.message +'</li>');
                    } else {
                        _this.ele.append('<div class="no-data">'+ res.message +'</div>');
                    }
                }
            });
        },
        /**
         * 加载分页
         * @return {Undefind}
         */
        loadPage: function() {
            var _this = this,
                config = _this.config;
            if (config.pagination) {
                var option = {
                   total: config.total,
                   currPage: config.curr,
                   click: function (data) {
                       config.params.page = data.page;
                       $(window).scrollTop(0);
                       _this.loadRender(config.params);
                   }
               };
               tools.pageTurn(option);
            }
        }
    };
    // 添加到window对象上
    window.RenderHtml = RenderHtml;
    //封装到jquery对象上去
    $.fn.renderHtml = function(config) {
        var renderHtml = new RenderHtml(this, config);
        return renderHtml.init();
    }
})(window.jQuery || $);

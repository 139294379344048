(function(global, $, undefined) {
    'use strict';

    global.tools = global.tools || {};

    /**
     * 图片弹出层
     * @param option
     * {
     *  list: 图片列表选择器，默认为‘#pictureList’
     *  entry: 列表条目选择器，默认为‘li’
     *  img: 图片选择器，默认为‘img.scale-pic’
     *  title: 标题选择器，默认为‘.picture-tit > span’
     * }
     */
    global.tools.popupPicture = function(option, callback) {
        var windowWidth = $(window).width();
        var windowHeight = $(window).height();
        var imgWidth = 0;
        var imgHeight = 0;
        var picIndex = 0;
        var imageSrc = '';
        var title = '';
        var top = 0;
        var left = 0;
        var prevClickTime = 0;
        var list = option.list || '.picture-lst';
        var imgElement = option.img || 'img.scale-pic';
        var titleElement = option.title || '.picture-tit > span';
        var entryElement = option.entry || 'li';
        var viewBtn = option.viewBtn || '';

        $(window).on('resize', function() {
            windowWidth = $(window).width();
            windowHeight = $(window).height();

            computeImgPosition();

            $(".pop-box").css({
                "left" : left,
                "top" : top
            });
        });

        if(viewBtn) {
            //点击图片
            $(list).on('click', viewBtn, function(e) {
                var $li = $(this).parents(entryElement);
                picIndex = $li.index();
                imageSrc = $li.find(imgElement).attr("src");
                title = $li.find(titleElement).text();
                // console.log(title)
                popupLayer();
            });
        } else {
            //点击图片
            $(list).on('click', entryElement, function(e) {
                // console.log($(this))
                picIndex = $(this).index();
                imageSrc = $(this).find(imgElement).attr("src") || $(imgElement).attr("src");
                title = $(this).find(titleElement).text() || $(this).parent().parent().find(titleElement).text();
                // console.log(title)
                popupLayer();
            });
        }

        $('body').on('click', '.picture-popup > .pop-prev', function() {
            //设置500毫秒一次有效点击，防止用户频繁点击
            var clickTime = new Date().getTime();
            if(clickTime - prevClickTime > 500) {
                $('.pop-img > img').attr('style', '');
                prevPic();
                prevClickTime = clickTime;
            }
        });
        $('body').on('click', '.picture-popup > .pop-next', function() {
            var clickTime = new Date().getTime();
            if(clickTime - prevClickTime > 500) {
                $('.pop-img > img').attr('style', '');
                nextPic();
                prevClickTime = clickTime;
            }
        });
        $('body').on('click', '.picture-popup > .pop-remove', function() {
            closePopup();
        });

        function computeImgPosition() {
            imgWidth = $(".pop-img > img").width();
            imgHeight = $(".pop-img > img").height();

            if(imgWidth > 1280){
                imgWidth = 1200;
            } else if(imgWidth < 300){
                imgWidth = 300;
            }

            if(imgHeight > 720){
                imgHeight = 700;
                if(imgHeight > windowHeight) {
                    imgHeight = windowHeight;
                }
            } else if (imgHeight < 175) {
                imgHeight = 175;
            }

            $('.pop-img > img').css({
                "width": imgWidth,
                "height": imgHeight
            });

            left = (windowWidth - imgWidth - 30) / 2;
            top = (windowHeight - imgHeight - 30) / 2;

            callback && callback($(list).find(entryElement).eq(picIndex));
        }

        function popupLayer() {// 图库弹出层
            var layer = '<div class="picture-popup"><div class="opa80 pop-remove"></div>' +
                '<a class="pop-close pop-remove"></a>' +
                '<a class="pop-btn pop-prev"></a>' +
                '<a class="pop-btn pop-next"></a>' +
                '<div class="pop-box"><div class="pop-img"><img src="' + imageSrc + '"/><p class="pop-tit"><span>' + title + '</span></p></div></div>' +
                '</div>';
            $(document.body).append(layer);

            $(".opa80").animate({
                opacity : 1
            }, 500);
            $(".pop-btn").animate({
                opacity : 1
            }, 500);
            $(".pop-close").animate({
                opacity : 1
            }, 500);

            computeImgPosition();

            $(".pop-box").css({
                "top" : top
            });

            $(".pop-box").animate({
                left : left,
                opacity : 1
            }, 500);
        }

        function closePopup() {
            $(".picture-popup").remove();
        }

        //上一张
        function prevPic() {
            var entrys = $(list).find(entryElement);
            var total = entrys.length;

            picIndex -= 1;
            if (picIndex < 0) {
                picIndex = total - 1;
            }
            var $img = $(entrys).eq(picIndex).find(imgElement).attr("src");
            var $tit = $(entrys).eq(picIndex).find(titleElement).html();

            $(".pop-img > img").animate({opacity: 0}, 0).attr("src",$img).animate({opacity: 1}, 200);
            $(".pop-tit > span").animate({opacity: 0}, 0).html($tit).animate({opacity: 1}, 200);

            computeImgPosition();

            $(".pop-box").css({
                "left" : left,
                "top" : top
            });
        }

        //下一张
        function nextPic() {
            var entrys = $(list).find(entryElement);
            var total = entrys.length;

            picIndex += 1;
            if (picIndex >= total) {
                picIndex = 0;
            }

            var $img = $(entrys).eq(picIndex).find(imgElement).attr("src");
            var $tit = $(entrys).eq(picIndex).find(titleElement).html();

            $(".pop-img > img").animate({opacity: 0}, 0).attr("src",$img).animate({opacity: 1}, 200);
            $(".pop-tit > span").animate({opacity: 0}, 0).html($tit).animate({opacity: 1}, 200);

            computeImgPosition();

            $(".pop-box").css({
                "left" : left,
                "top" : top
            });
        }
    }

})(window, jQuery);

/**
 * Created by chengwb on 2016/11/8.
 */
(function(global, $, undefined){
    global.tools = global.tools || {};

    /**
     * 翻页；可以使用，待后续优化；
     * @param option
     * {
     *  selector: 翻页的容器选择器，默认值为'.page'
     *  total: 总页数
     *  currPage: 当前页数
     *  homeClass: 首页按钮样式class，默认为'first-page'
     *  lastClass: 尾页按钮样式class，默认为'last-page'
     *  nextClass: 下一页按钮样式class，默认为'page-down'
     *  prevClass: 上一页按钮样式class，默认为'page-up'
     *  click: 页码点击事件处理方法
     *  params: 点击事件处理方法的参数
     * }
     */
    global.tools.pageTurn = function(option) {
        var totalPages = option.total;
        var currPage = option.currPage;
        var homeClass = option.homeClass || 'first-page';
        var lastClass = option.lastClass || 'last-page';
        var nextClass = option.nextClass || 'page-down';
        var prevClass = option.prevClass || 'page-up';
        var container = option.selector || '.page';
        var params = option.params || {};

        //一个多面存在多个翻页时，点击事件避免覆盖
        global._pageTurnClick = global._pageTurnClick || [];
        global._pageTurnClick.push(option.click);

        if(!totalPages || !currPage || totalPages <= 0 || currPage <= 0){
            return;
        }

        if(currPage > totalPages) {
            currPage = totalPages;
        }

        var html = '';
        var home = currPage === 1 ? 0 : 1;
        var prev = currPage === 1 ? 0 : currPage - 1;
        var last = currPage === totalPages ? 0 : totalPages;
        var next = currPage === totalPages ? 0 : currPage + 1;
        var limits = option.limits || 3;//单边限制，设置为n则最多显示2n-1项
        var pageNum = 0;
        var i = 0;

        //限制1-5，默认为3
        if(limits <= 0) {
            limits = 1;
        } else if(limits > 5) {
            limits = 5;
        }

        //生成onclick字符串
        function isClick(page) {
            if(page && page !== currPage) {
                params.page = page;
                return ' valid" onclick="_pageTurnClick[' + (global._pageTurnClick.length-1) + '](' + JSON.stringify(params).replace(/"/g, '\'')  + ')"';
            } else {
                params.page = 0;
                return ' invalid"';
            }
        }

        //翻页的首部
        html += '<a class="' + homeClass + isClick(home) + '>首页</a>';
        html += '<a class="' + prevClass + isClick(prev) + '>上一页</a>';

        //翻页中间部分处理
        if(totalPages >= limits * 2 - 1) {//总页数大于等于总显示数时
            if (currPage - limits > 0) {
                html += '<a class="invalid" style="font-weight: bold;">...</a>';
            }

            if(totalPages - limits >= currPage) {//
                var counts = 0;
                for (i = limits - 1; i >= 0; i--) {
                    pageNum = currPage - i;
                    if (pageNum <= 0) {
                        counts++;
                        html += '<a class="' + (counts === currPage ? 'curr' : '') + isClick(counts) + '>' + counts + '</a>';
                    } else {
                        html += '<a class="' + (pageNum + counts === currPage ? 'curr' : '') + isClick(pageNum + counts) + '>' + (pageNum + counts) + '</a>';
                    }
                }

                for (i = 1; i < limits; i++) {
                    pageNum = (counts === 0 ? currPage + i : limits + i);
                    if (pageNum > totalPages) {
                        break;
                    } else {
                        html += '<a class="' + isClick(pageNum) + '>' + pageNum + '</a>';
                    }
                }

                if (counts + currPage + limits <= totalPages) {
                    html += '<a class="invalid" style="font-weight: bold;">...</a>';
                }
            } else {
                for (i = totalPages - (limits * 2 - 2); i <= totalPages; i++) {
                    html += '<a class="' + (currPage === i ? 'curr' : '') + isClick(i) + '>' + i + '</a>';
                }
            }
        } else {//总页数小于总显示数时
            for (i = 1; i <= totalPages; i++) {
                html += '<a class="' + (currPage === i ? 'curr' : '') + isClick(i) + '>' + i + '</a>';
            }
        }

        //翻页尾部
        html += '<a class="' + nextClass + isClick(next) + '>下一页</a>';
        html += '<a class="' + lastClass + isClick(last) + '>末页</a>';

        $(container).html(html);
        if(window._bd_share_main){
            window._bd_share_main.init();
        }

    };
})(window, jQuery);

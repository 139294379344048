(function($) {
	function Dialog(config) {
		this.config = {
			title: '',
			width: 600,
			height: 300,
			message: '出错了',
			footer: true,
			buttons: null,
			logShow: false,
			ready: function(){},
			callback: function(){

			}
		};
		//默认参数扩展
		if (config && $.isPlainObject(config)) {
			$.extend(this.config, config);
		};
		this.init();
	}
	Dialog.prototype.init = function() {
		this.body = $('body');
		//创建弹出窗口
		this.dialogWrap = $('<div class="ui-dialog-wrap"></div>');
		//创建遮罩层
		this.mask = $('<div class="ui-dialog-mask"></div>');
		//创建弹出窗口dialog
		this.dialog = $('<div class="ui-dialog"></div>');
		//创建弹出窗口head
		this.dialogHd = $('<div class="ui-dialog-hd"><span class="ui-dialog-line"></span><h2 class="ui-dialog-title">购买须知</h2><i class="ui-dialog-close">×</i></div>');
		//创建弹出窗口body
		this.dialogBd = $('<div class="ui-dialog-bd"></div>');
		//创建弹出窗口footer
		this.dialogFt = $('<div class="ui-dialog-ft ui-border-t"></div>');
		//渲染DOM
		this.render();
		this.show();
		this.event();
	};
	Dialog.prototype.event = function() {
		var _this = this,
			mask = this.mask;
		$(document).on('click','.ui-dialog-close,.ui-dialog-mask',function(){
			typeof _this.config.callback === 'function' && _this.config.callback();
			_this.hide();
		});
	};
	Dialog.prototype.render = function() {
		var _this = this,
			body = $('body'),
			config = this.config,
			dialogWrap = this.dialogWrap,
			mask = this.mask,
			dialog = this.dialog,
			dialogHd = this.dialogHd,
			dialogBd = this.dialogBd,
			dialogFt = this.dialogFt;

		if (config.mask) {
			dialogWrap.append(mask);
		};
		//如果传了标题
		if (config.title) {
			dialogHd.find('.ui-dialog-title').html(config.title);
			dialog.append(dialogHd);
		};
		//如果传了信息文本
		if (config.message) {
			dialog.append(dialogBd.html(config.message));
		};
		if (config.buttons) {
			_this.creactButton(config.buttons, dialogFt);
			dialog.append(dialogFt);
		}
		if (config.logShow === true){
            setTimeout(function () {
                _this.hide();
            },2000)
        }
		dialogWrap.append(dialog);
		body.append(dialogWrap);
		config.ready && config.ready();
		_this.computed();
	};
	Dialog.prototype.computed = function() {
		var _this = this,
			config = this.config,
			$dialog = $('.ui-dialog'),
			dialogHdHeight = $('.ui-dialog-hd').outerHeight(),
			$dialogBd = $('.ui-dialog-bd'),
			dialogFtHeight = $('.ui-dialog-ft').outerHeight(),
			dialogBdHeight;
		$dialog.css({
			width: config.width,
			height: config.height,
			marginTop: -config.height / 2,
			marginLeft: -config.width / 2
		});

		if (config.footer) {
			dialogBdHeight = config.height - dialogHdHeight - dialogFtHeight - 40
		}else{
			dialogBdHeight = config.height - dialogHdHeight - 40
		}
		$dialogBd.css({
			height: dialogBdHeight
		});
	};
	Dialog.prototype.creactButton = function(buttons, footer) {
		var _this = this,
			config = _this.config;
		$(buttons).each(function() {
			var text = this.text ? this.text : '按钮' + index++,
				callback = this.callback ? this.callback : null;
			button = $('<a class="btn ' + this.className + '">' + text + '</a>');
			if (callback) {
				button.click(function() {
					var isClose = callback();
					if (isClose !== false) {
						_this.hide();
					}
				});
			} else {
				button.click(function() {
					_this.hide();
				});
			}
			if (config.footer) {
				footer.append(button);
			}
		});
	};
	Dialog.prototype.hide = function() {
		var _this = this;
		_this.dialogWrap.removeClass('show').addClass('hide');
		setTimeout(function() {
			_this.dialogWrap.remove();
		}, 250);
	};
	Dialog.prototype.show = function() {
		var _this = this;
		setTimeout(function() {
			_this.dialogWrap.removeClass('hide').addClass('show');
		}, 100);
	};
	window.Dialog = Dialog;
	$.dialog = function(config) {
		return new Dialog(config);
	}
})(window.jQuery || $);
